.planner-large .bed-wrapper{
    border: solid 1px silver;
    border-radius: 5px;
    margin-bottom: 8px;
    padding: 10px;
    background: white;
}

.bed-buttons {
    text-align: right;
}

.planner-large .bed-wrapper .bed-title {
    line-height: 22px;
    font-weight: bold;
    font-size: 16px;
}

.planner-large .planting-wrapper {
    border-top: solid 1px silver;
    padding: 8px 0;
    cursor: pointer;
}

.planting-wrapper:hover {
    background: #f9f9f9;
}


.planting-date-header{
    line-height: 22px;
    font-weight: bold;
}
.planting-wrapper .planting-name{
}

.planner-large .status-dropdown{
     padding-top: 12px;
}


.planner-large .planting-wrapper .planting-date {
    padding: 12px 0;
}

.planner-large .planting-wrapper .on-track-warning{
    margin-left: 12px;
}

.planner-large .planting-wrapper .planting-name{
    margin-top:16px;
    margin-left: 8px;
}

.planner-compact .add-first-planting-button{
    display:none;
}

.planner-compact .bed-title {
    margin:0;
    line-height: 32px;
    padding-left: 12px;
}

.planner-compact .bed-wrapper {
    background: #fffdf7;
    border-bottom: solid 1px silver;
}

.planner-compact .bed-wrapper,
.planner-compact .planting-name{
    line-height: 32px;
}

.planner-compact .periods-wrapper{
    padding-left: 12px;
    margin-top: 3px;
    border-radius: 0 !important;
}
.planner-compact .period-block{
    margin: 0 1px !important;
    border-radius: 0 !important;
}