.bed-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 200px);
    background: #efefef;
    border: solid 1px black;
    overflow:auto;
}

.bed {
    position: absolute;
    background-color: #538a44;
    cursor: pointer;
    transform-origin: center;
}
.bed-controls {
    padding-left: 10px;
    margin-top: -35px;
    display: block;
    height: 35px;
    line-height: 35px;
    margin-left: -1px;
    margin-right: -1px;
    border-top: solid 1px black;
    border-left: solid 1px black;
    border-right: solid 1px black;
    background: white;
}